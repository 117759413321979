/* eslint-disable camelcase */
import "~/styles/helpers/resetNew.scss"
import "~/styles/helpers/animations.scss"
import "~/styles/helpers/moving-objects.scss"
import "~/styles/ui/index.scss"

import { createWeb3Modal } from "@web3modal/wagmi/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { WagmiProvider, fallback, unstable_connector, createConfig, http } from "wagmi"
import * as amplitude from "@amplitude/analytics-browser"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { createRoot } from "react-dom/client"

import React, { Suspense, lazy, useEffect, useState } from "react"
import { mainnet, polygon, optimism, base, bsc, sepolia } from "wagmi/chains"

import { injected, walletConnect } from "wagmi/connectors"
import { defineChain } from "viem"
import ErrorBoundary from "~/providers/ErrorBoundary/ErrorBoundary.jsx"

import Main from "../components/pages/Main/Main"
import { mainconfig } from "../config"
import Loader from "~/components/shared/Loader/Loader.jsx"
import { GlobalProvider } from "../providers/GlobalContext/GlobalContext"
import { ampli, track } from "../helpers/amplitude"

const Customize = lazy(() => import("../components/pages/Customize/Customize"))

amplitude.init(mainconfig.services.amplitude.key, {
    defaultTracking: true
})

const infuraId = mainconfig.services.infura.key
const alchemyId = mainconfig.services.alchemy.key

const projectId = mainconfig.services.walletconnect.key
const customBsc = defineChain({
    ...bsc,
    rpcUrls: {
        default: {
            http: ["https://bsc-dataseed1.bnbchain.org"]
        }
    }
})
const selectedChains = [mainnet, polygon, customBsc, optimism, base]

const metadata = {
    name: "ToON.ORG",
    description: "Reserve $TOON Tokens Early",
    url: "https://get.toon.org",
    icons: ["https://get.toon.org/android-chrome-192x192.png"]
}

const connectors = [
    injected(),
    walletConnect({
        projectId,
        metadata,
        showQrModal: false
    })
]

const wagmiConfig = createConfig({
    chains: selectedChains,
    transports: {
        [mainnet.id]: fallback([
            unstable_connector(injected),
            http(`https://eth-mainnet.g.alchemy.com/v2/${alchemyId}`)
        ]),
        /* [sepolia.id]: http("https://ethereum-sepolia-rpc.publicnode.com"), */
        [polygon.id]: http(`https://polygon-mainnet.infura.io/v3/${infuraId}`, {
            batch: true
        }),
        [customBsc.id]: fallback([
            http(`https://bsc-rpc.publicnode.com`),
            http(`https://bsc-dataseed.binance.org`),
            http(`https://bsc-dataseed1.defibit.io`),
            http(`https://bsc-dataseed1.ninicoin.io`),
            http(`https://bsc-dataseed2.defibit.io`),
            http(`https://bsc-dataseed3.defibit.io`),
            http(`https://bsc-dataseed4.defibit.io`),
            http(`https://bsc-dataseed2.ninicoin.io`),
            http(`https://bsc-dataseed3.ninicoin.io`),
            http("https://bsc-dataseed1.binance.org/"),
            http("https://bsc-dataseed2.binance.org/"),
            http("https://bsc-dataseed4.ninicoin.io/"),
            http("https://bsc-rpc.publicnode.com")
        ]),
        [base.id]: http(`https://base-mainnet.g.alchemy.com/v2/SKNZEVz9gLqyHML_rgjwxHTNKf6Fh-cz`),
        [optimism.id]: http(`https://optimism-mainnet.infura.io/v3/${infuraId}`)
        /* [arbitrum.id]: http(`https://arbitrum-mainnet.infura.io/v3/${config.services.infura.key}`) */
    },
    connectors
})

createWeb3Modal({
    defaultChain: mainnet,
    wagmiConfig,
    projectId,
    enableAnalytics: true,
    privacyPolicyUrl: "https://get.toon.org/privacy",
    excludeWalletIds: [
        "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393",
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"
    ],
    featuredWalletIds: ["1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369"],
    themeVariables: {
        "--w3m-accent": "#F79F1F"
    }
})

const queryClient = new QueryClient()

const root = createRoot(document.getElementById("page-container"))

const AppRouter = ({ isNew }) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route
                    path="/customize/*"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Customize />
                        </Suspense>
                    }
                />
            </Routes>
        </Router>
    )
}
const App = () => {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const userMainGroup = localStorage.getItem("userMainGroup")

        if (!userMainGroup) {
            const isAlternative = Math.random() < 0.5 // 50%
            localStorage.setItem("userMainGroup", isAlternative ? "new" : "old")
            console.log("userMainGroup set to:", localStorage.getItem("userMainGroup"))
        }

        track("AB_site_versions", { version: userMainGroup })

        /* ---------------------------- MODAL USER GROUP ---------------------------- */
        let userGroup = localStorage.getItem("userGroup")

        if (!userGroup) {
            const isAlternative = Math.random() < 0.5 // 50%
            localStorage.setItem("userGroup", isAlternative ? "custom" : "default")
            userGroup = isAlternative ? "custom" : "default"
            track(ampli.userGroup, { userGroup, version: localStorage.getItem("userMainGroup") })
        }
        setIsLoading(false)
    }, [])

    if (isLoading)
        return (
            <div className="lds-circle-loader">
                <div className="circle" />
            </div>
        )

    return (
        <WagmiProvider config={wagmiConfig} reconnectOnMount={false}>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                    <GlobalProvider>
                        <AppRouter /* isNew={localStorage.getItem("userMainGroup") === "new"} */ />
                    </GlobalProvider>
                </ErrorBoundary>
            </QueryClientProvider>
        </WagmiProvider>
    )
}

root.render(<App />)
